.reinvite_container {
  padding: 1rem;
}

.reinvite_info {
  padding: 0.5rem 1rem;
}

.reinvite_info p {
  font-weight: 600;
}