.rdn-form {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.rdn-form .form-date {
    width: 180px;
}

.rdn-modal-duplicate-actions {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    gap: 40px;
}

.rdn-modal-duplicate-title {
    text-align: center;
    color: #000;
    font-family: Arial;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.form-rdn-wrapper {    
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.rdn-form .form-select {
    width: 20%;
}

.form-rdn-delete-content-wrapper {
    min-height: 20vh;
}

.form-rdn-delete-content  {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.form-rdn-delete-btn {
    margin-top: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.form-rnd-delete-btn-width {
    width: 200px;
}

.form-rdn-delete {
    height: 800px;
}

.form-rdn-delete h3 {
    text-align: center;
    color: #000;
    font-family: Arial;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}