.forgot_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgot_container_card {
  width: 500px;
  height: 300px;
  background-color: #000000;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  gap: 1rem;
}

.forgot_container_card p {
  color: white;
  text-align: center;
  font-weight: 700;
  font-size: 1rem;
}

.forgot_container_log {
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgot_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}