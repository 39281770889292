body {
	background-color: #e0e0e0;
}

.card {
	background: #2f2f2f !important;
}

.bg-dark {
	background: #2f2f2f !important;
}

.bg-secondary {
	background: #2f2f2f !important;
}

.bg-primary {
	background: #ffc600 !important;
}

.border-secondary {
	border-color: #2f2f2f !important;
}

.border-primary {
	border-color: #ffc600 !important;
}

.border-white {
	border-color: white !important;
}

.menu-custom .li {
	border-color: white !important;
	background: #2f2f2f !important;
	color: white !important;
	cursor: pointer !important;
}

.dropdown-item:focus {
	background: #ffc600 !important;
}

.select__control--is-focused {
	box-shadow: 0 0 0 1px #ffc600 !important;
	border-color: #ffc600 !important;
}

.form-control:focus,
.form-select:focus {
	box-shadow: 0 0 0 1px #ffc600 !important;
	border-color: #ffc600 !important;
}

.menu-custom svg {
	fill: white;
}

.menu-custom .active {
	border-color: white !important;
	background: #ffc600 !important;
	color: white;
}

.input-group-text {
	background: #2f2f2f !important;
	cursor: pointer;
}

.btn-primary {
	background: #ffc600 !important;
	color: #fff !important;
	border-color: #ffc600 !important;
}

.text-primary {
	color: #ffffff !important;
}

.text-secondary {
	color: #ffc600 !important;
}

.vh-92 {
	height: 92vh
}

.vh-93 {
	height: 100vh
}

.w-30 {
	width: 30%
}

.w-40 {
	width: 40%;
}

.w-90 {
	width: 90%;
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

::-webkit-scrollbar-track {
	background-color: #2f2f2f;
	-webkit-border-radius: 10px;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: #ffc600;
}

#rfs-btn {
	background: #2f2f2f !important;
	color: white !important;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
	background: #2f2f2f !important;
	color: white !important;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ li:hover {
	background: #ffc600 !important;
	color: white !important;
}

.active>.page-link,
.page-link.active {
	background-color: #2f2f2f;
	border-color: #2f2f2f;
}

.page-link {
	color: #2f2f2f;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	color: #ffffff;
	background-color: #2f2f2f;
	border-color: #ffc600;
}


.nav-tabs {
	--bs-nav-tabs-border-width: 2px;
	--bs-nav-tabs-border-color: #dee2e6;
	--bs-nav-tabs-border-radius: 0.375rem;
	--bs-nav-tabs-link-hover-border-color: #ffc600 #ffc600 #ffc600;
	--bs-nav-tabs-link-active-color: #495057;
	--bs-nav-tabs-link-active-bg: #fff;
	color: #2f2f2f;
}

.nav-tabs .nav-link {
	border-color: #ffc600;
}

.nav {
	--bs-nav-link-color: #2f2f2f;
	--bs-nav-link-hover-color: #2f2f2f;
	--bs-nav-link-disabled-color: #6c757d;
}

tr {
	cursor: pointer;
}

@media(max-width: 1580px) {
  html {
    font-size: 90.75%;
  }
}

@media(max-width: 720px) {
  html {
    font-size: 87.50%;
  }
}