.system-support-container {
  border: 1px solid #635f5f;
  border-radius: 1rem;
  padding: 1rem;
  margin: 0px 8px;
  width: 100%;
}

.system-support-container__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}

.system-support-container__header span {
  font-size: 1.5rem;
  font-weight: 900;
  color: #FFC600;
}

.system-support-container__header img {
  width: 3.75rem;
  height: 3.75rem;
}

.system-support-container__footer {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1rem;
}

.system-support-container__footer span {
  font-size: 1rem;
  font-weight: 900;
  color: #000000;
}

.system-support-container__footer a {
  text-decoration: none;
  color: #000;
}

@media (max-width: 1500px) {
  .system-support-container {    
    width: auto;
  }

  .system-support-container__header {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 1rem;
  }
  
}
