.input-checkbok {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(18, 1fr);

  .item-0 {
    grid-column: 1/1;
    grid-row: 1/1;
  }

  .item-1 {
    grid-column: 1/1;
    grid-row: 2/2;
  }

  .item-2 {
    grid-column: 1/1;
    grid-row: 3/3;
  }

  .item-3 {
    grid-column: 1/1;
    grid-row: 4/4;
  }

  .item-4 {
    grid-column: 1/1;
    grid-row: 5/5;
  }

  .item-5 {
    grid-column: 1/1;
    grid-row: 6/6;
  }

  .item-6 {
    grid-column: 1/1;
    grid-row: 7/7;
  }
  
  .item-7 {
    grid-column: 1/1;
    grid-row: 8/8;
  }
  .item-8 {
    grid-column: 1/1;
    grid-row: 9/9;
  }
  .item-9 {
    grid-column: 1/1;
    grid-row: 10/10;
  }
  .item-10 {
    grid-column: 1/1;
    grid-row: 11/11;
  }

  .item-11 {
    grid-column: 1/1;
    grid-row: 12/12;
  }

  .item-12 {
    grid-column: 1/1;
    grid-row: 13/13;
  }

  .item-13 {
    grid-column: 1/1;
    grid-row: 14/14;
  }

  .item-14 {
    grid-column: 1/1;
    grid-row: 15/15;
  }

  .item-15 {
    grid-column: 1/1;
    grid-row: 16/16;
  }

  .item-16 {
    grid-column: 1/1;
    grid-row: 17/17;
  }

  .item-17 {
    grid-column: 1/1;
    grid-row: 18/18;
  }


  .item-18 {
    grid-column: 2/2;
    grid-row: 1/1;
  }

  .item-19 {
    grid-column: 2/2;
    grid-row: 2/2;
  }

  .item-20 {
    grid-column: 2/2;
    grid-row: 3/3;
  }

  .item-21 {
    grid-column: 2/2;
    grid-row: 4/4;
  }

  .item-22 {
    grid-column: 2/2;
    grid-row: 5/5;
  }

  .item-23 {
    grid-column: 2/2;
    grid-row: 6/6;
  }

  .item-24 {
    grid-column: 2/2;
    grid-row: 7/7;
  }
  
  .item-25 {
    grid-column: 2/2;
    grid-row: 8/8;
  }
  .item-26 {
    grid-column: 2/2;
    grid-row: 9/9;
  }
  .item-27 {
    grid-column: 2/2;
    grid-row: 10/10;
  }
  .item-28 {
    grid-column: 2/2;
    grid-row: 11/11;
  }

  .item-29 {
    grid-column: 2/2;
    grid-row: 12/12;
  }

  .item-30 {
    grid-column: 2/2;
    grid-row: 13/13;
  }

  .item-31 {
    grid-column: 2/2;
    grid-row: 14/14;
  }

  .item-32 {
    grid-column: 2/2;
    grid-row: 15/15;
  }

  .item-33 {
    grid-column: 2/2;
    grid-row: 16/16;
  }

  .item-34 {
    grid-column: 2/2;
    grid-row: 17/17;
  }

  .item-35 {
    grid-column: 2/2;
    grid-row: 18/18;
  }


  .item-36 {
    grid-column: 3/3;
    grid-row: 1/1;
  }

  .item-37 {
    grid-column: 3/3;
    grid-row: 2/2;
  }

  .item-38 {
    grid-column: 3/3;
    grid-row: 3/3;
  }

  .item-39 {
    grid-column: 3/3;
    grid-row: 4/4;
  }

  .item-40 {
    grid-column: 3/3;
    grid-row: 5/5;
  }

  .item-41 {
    grid-column: 3/3;
    grid-row: 6/6;
  }

  .item-42 {
    grid-column: 3/3;
    grid-row: 7/7;
  }
  
  .item-43 {
    grid-column: 3/3;
    grid-row: 8/8;
  }
  .item-44 {
    grid-column: 3/3;
    grid-row: 9/9;
  }
  .item-45 {
    grid-column: 3/3;
    grid-row: 10/10;
  }
  .item-46 {
    grid-column: 3/3;
    grid-row: 11/11;
  }

  .item-47 {
    grid-column: 3/3;
    grid-row: 12/12;
  }

  .item-48 {
    grid-column: 3/3;
    grid-row: 13/13;
  }

  .item-49 {
    grid-column: 3/3;
    grid-row: 14/14;
  }

  .item-50 {
    grid-column: 3/3;
    grid-row: 15/15;
  }

  .item-51 {
    grid-column: 3/3;
    grid-row: 16/16;
  }

  .item-52 {
    grid-column: 3/3;
    grid-row: 17/17;
  }

  .item-53 {
    grid-column: 3/3;
    grid-row: 18/18;
  }
}