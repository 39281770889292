.presentation-report__container {
  display: grid;
  grid-template-areas: 
  "header"
  "report_all"
  "report"
  ;
  gap: 1rem;  
}

.presentation-report__container__header {
  display: flex;
  flex-direction: column;
  max-width: 68.625rem;
  gap: 1rem;
}

.presentation-report__container__header__btn-header button {
  width: 10rem;
}

.presentation-report__container__report__grid {
  display: grid;
  grid-template-columns: repeat(4);  
}

.presentation-report__container__report__card  {
  padding: 1rem;
  border-radius: 1rem;
  max-width: 18.75rem;  
  background-color: #fff;
}

.presentation-report__container__report__card h3 {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}

.presentation-report__container__report__card__img  {
  display: flex;
  align-items: center;
  justify-content: center;  
}

.presentation-report__container__report__card__img img {
  width: 14.375rem;
  height: 10.4375rem;
  border-radius: 1rem;
}

.presentation-report__container__report {
  grid-area: report;
  padding-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  max-width: 18.75rem;  
}

.presentation-report__container__report__wraper {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.presentation-report__container__report__card__ranking {
  text-align: center;
}

.presentation-report__container__report_all h3 {    
  font-weight: bold;
  font-size: 1.1rem;
}

.presentation-report__container__report_all__wraper {
  display: flex;
  gap: 1rem;
  max-width: 68.625rem;
}

.presentation-report__container__report_all__left {
  background-color: #fff;
  width: 100%;
  padding: 0.5rem;
  border-radius: 1rem;
}

.presentation-report__container__report_all__wraper p {
  margin: 0;
  line-height: 28px;
}

.presentation-report__container__report_all__left h4 {
  font-size: 1rem;
  font-weight: bold;
}

.presentation-report__container__report_all__rigth h4 {
  font-size: 1rem;
  font-weight: bold;
}

.presentation-report__container__report_all__rigth {
  background-color: #fff;
  width: 100%;
  padding: 0.5rem;
  border-radius: 1rem;
}

@media only screen and (max-width: 1130px) {
  .presentation-report__container__report {
    grid-area: report;
    padding-bottom: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
  
}

@media only screen and (max-width: 790px) {
  .presentation-report__container__report {
    grid-area: report;
    padding-bottom: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  
}

@media only screen and (max-width: 519px) {  
  .presentation-report__container__report {
    grid-area: report;
    padding-bottom: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
}