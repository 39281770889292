*:focus {
  outline: none;
}

.upload-drag-container {
  width: 100%;
  height: 200px;
  border: 1px solid #6C757D;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px 10px 13px -7px #000000, 3px 3px 15px 5px rgba(0, 0, 0, 0);
  box-shadow: 0px 10px 13px -7px #000000, 3px 3px 15px 5px rgba(0, 0, 0, 0);
}

.upload-drag-container__cicle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid #6C757D;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px 10px 13px -7px #000000, 3px 3px 15px 5px rgba(0, 0, 0, 0);
  box-shadow: 0px 10px 13px -7px #000000, 3px 3px 15px 5px rgba(0, 0, 0, 0);
}

.upload-drag-container__cicle__plus span {
  font-size: 80px;
  font-weight: 100;
}

.upload-drag__description {
  width:100%;
  text-align: center;
}

.upload-drag__description p {  
  margin-top: 15px;  
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #666666;
}