.show-form {
    display: flex;    
    align-items: center;
    gap: 20px;
}


.form-group-container {
    display: flex;
    gap: 10px;
}

.form-group-container label {
    font-weight: bold;
}