.form-profile-container {
  padding: 1rem;
  display: grid;
  gap: 1rem; 
}


.form-personal {
  display: grid;
  grid-template-columns: 25rem 1fr;
  grid-template-areas: 
    "photo user"
  ;
  column-gap: 1rem;
}

.form-profile-photo {
  grid-area: photo;
}

.form-profile-user {
  grid-area: user;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.form-profile-user__name {
  grid-column: 1/2;
  grid-row: 1/1;
}

.form-profile-user__email {
  grid-column: 2/3;
  grid-row: 1/1;
}

.form-profile-user__cpf {
  grid-column: 3/3;
  grid-row: 1/1;
}

.form-profile-user__phone {
  grid-column: 1/2;
  grid-row: 2/2;
}

.form-profile-user__phone {
  grid-column: 1/2;
  grid-row: 2/2;
}

.form-profile-user__birthDay {
  grid-column: 2/3;
  grid-row: 2/2;
}

.form-profile-user__status {
  grid-column: 3/3;
  grid-row: 2/2;
}

.form-profile-user__password {
  grid-column: 1/2;
  grid-row: 3/3;
}

.form-profile-user__lang {
  grid-column: 2/3;
  grid-row: 3/3;
}

.form-profile-user__defaultGroup {
  grid-column: 3/3;
  grid-row: 3/3;
}

.form-profile-user__nexusAt {
  grid-column: 4/4;
  grid-row: 3/3;
}

.form-group {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

.form-footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

.form-atencion p span {
  font-weight: bold;
}

.form-btn {  
  display: flex;
  justify-content: flex-end;
}

.form-btn .btn {
  width: 150px;
}

.modal-danger-logout__actions {
 display: flex;
 align-items: center;
 justify-content: center;
 gap: 2rem;
 padding: 1rem;
}

.modal-danger-logout__actions button {
  width: 9.25rem;  
}