.button-week-submit {
    width: 145px;
    padding: 0;    
}

.title-group-week {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: Arial; 
}

.alert-week {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: Arial; 
}

.alert-week span {
    font-weight: bold;
}

.alert-week .goback{
    color: #007bff;
    cursor: pointer;
}