.list-user {
    width: 972px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.member {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.company {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.member span {
    display: block;
    margin-bottom: 10px;
}

.company span {
    display: block;
    margin-bottom: 10px;
}

.company .text-focus {
    color: #000;
    font-family: Arial;
    font-style: normal;
    font-weight: 900;
    line-height: 24px;
}

.member .text-focus {
    color: #000;
    font-family: Arial;
    font-style: normal;
    font-weight: 900;
    line-height: 24px;
}

.member p {
    color: #000;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
}

.company p {
    color: #000;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
}