.point-container {
  padding: 1.5625rem 0;
  max-width: 68.125rem;
  display: grid;
  grid-template-areas: 
  "point"
  "title"
  "recurrence";
  row-gap: 1rem;
  background-color: #CED4DA;
  border-radius: 8px;
}

.point-container__form {
  margin: 0 auto;
  width: 44.125rem;
  display: flex;
  align-items: center;
  gap: 8rem;
}

.point-container__left {
  display: block;
  min-width: 100px;
  max-width: 100px;
}

.point-container__points {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 4rem;
  row-gap: 1rem;
}

.point-container__points p {
  text-align: center;
}

.point-container__recurrence__title {
  min-width: 100px;
}

.point-container__recurrence {
  margin: 0 auto;
  width: 44.125rem;
  grid-area: recurrence;
  display: flex;
  gap: 8rem;
  align-items: center;
}

.point-container__title {
  padding-left: 3rem;
  grid-area: title;
  font-weight: 600;
  margin: 0 auto;
  width: 44.125rem;
  display: flex;
  gap: 8rem;
  align-items: center;
  justify-content: center;
}