.training-meeting-container { 
    display: grid;
    grid-template-areas: 
        "training-meeting-container__header"
        "training-meeting-container__list"
    ;
}

.training-meeting-container__header {
    grid-area: training-meeting-container__header;
    display: flex;
    gap: 18rem;
}

.training-meeting-container__list {
    grid-area: training-meeting-container__list;
}

.training-meeting-container__list__form {
    max-width: 430px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.training-meeting-list {
    margin: 4px;
    padding: 8px;
}

.training-meeting-select {
    width: 400px;
    margin-bottom: 10px;
}

.training-meeting-title {
    color: #000;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}



.training-meeting-container .training-meeting-container__list .training-meeting-container__list__form .form-check-input {
    width: 4rem;
}


.week-footer {
    margin-top: 56px;
}

.week-footer p  span {
    color: #000;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;    
}

.week-footer button {
    width: 145px;
}

.button-show {
    width: 5.8125rem;
    background-color: #F36E25 !important;
}