.main-dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0.3125rem;
  margin: 1rem 0.5rem;
  gap: 0.5rem;

  border: 1px solid #635f5f;
  border-radius: 1rem;
}

.main-dashboard-container h2 {
  color: #FFC600;
}

.main-dashboard-container__cards {
  display: flex;
  border: 1px solid #635f5f;
  border-radius: 1rem;
  min-width: 51.875rem;
  cursor: pointer;
}

.main-dashboard-container__cards__container__left {
  background-color: #FFC600;
  min-width: 6.25rem;
  min-height: 6.25rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center; 
}

.main-dashboard-container__cards__container__rigth {
  display: flex;
  flex-direction: column;  
  flex: 1;
  padding: 0.625rem 1rem;
}

.main-dashboard-container__cards__container__left img {
  width: 3.75rem;
  height: 3.75rem;
}

.main-dashboard-container__cards__container__rigth__info  {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.main-dashboard-container__cards__container__rigth__info p:nth-child(1) {
  font-size: 1rem;
  font-weight: 900;
  color: #000;
}

.main-dashboard-container__carousel {
  padding: 1rem;
  width: 100%;  
}

@media (max-width: 1500px) {
  .main-dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;    
    border: none;        
  }

  .main-dashboard-container__cards {
    display: flex;
    border: 1px solid #635f5f;
    border-radius: 1rem;    
    min-width: 100%;
    cursor: pointer;
  }   
}