.sidebar-dashboard-container {
  height: 100%;
  padding: 1rem;
  background-color: #E0E0E0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sidebar-dashboard-container__header {
  display: flex;
  flex-direction: column;    
}

.sidebar-dashboard-container__header__img {
  display: flex;
  justify-content: center;  
}

.sidebar-dashboard-container__header__img img {
  width: 5.9375rem;
  height: 5.9375rem;
  border-radius: 50%;
}

.sidebar-dashboard-container__header__company {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sidebar-dashboard-container__header__company__info span {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.625rem;
  color: #000;
}

.sidebar-dashboard-container__month {
  display: flex;  
  align-items: center;
  justify-content: center;
}

.sidebar-dashboard-container__month p {
  font-size: 1.3rem;
  font-weight: 900;
}

.sidebar-dashboard-container__month p {    
  line-height: 1.625rem;
  color: #FFC600;
  margin-right: 0.5rem;
}

.sidebar-dashboard-container__system-info {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.sidebar-dashboard-container__score {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: #2F2F2F;
  border-radius: 4px;
  font-size: 1.5rem;
}

.sidebar-dashboard-container__score span {
  color: #FFF;
}

.sidebar-dashboard-container__score--color {
  color: #FFF500 !important;
}

.sidebar-dashboard-container__system-info p {
  font-size: 1.3rem;
}

@media (max-width: 1500px) {
  .sidebar-dashboard-container {    
    padding: 1rem;    
    background-color: #E0E0E0;        
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .sidebar-dashboard-container__system-info {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;    
  }
  
}