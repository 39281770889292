.ranking-container {
  border: 1px solid #635f5f;
  border-radius: 1rem;
  padding: 1rem;
  margin: 1rem 8px;  
  width: 100%;
}

.ranking-container h2 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 900;
  color: #FFC600;  
}

.ranking-container__table h3 {
  margin-top: 1rem;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  letter-spacing: 0.72px;
  color: red;
}

.ranking-container__table__items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ranking-container__table hr {
  width: 100%;
  height: 1px;
  border: 0px;
  border-top: 7px solid #0000001A;
  background-color: #0000001A;
}

.ranking-container__table__items--puctions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.ranking-container__table__items--cicle {
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
  background-color: red;
}

.ranking-container__table__extact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ranking-container__table__extact__header {
  display: flex;
  align-items: center;  
  width: 80%;  
}

.ranking-container__table__extact__header img {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 6.25rem;
}

.ranking-container__table__extact__header span {  
  font-size: 1rem;
  font-weight: 700;
  line-height: 2.5rem;
  latter-spacing: 0.8px;
}

.ranking-container__table__extact button {
  width: 100%;
  height: 2.5rem;
  border: none;
  border-radius: 8px;  
  font-size: 1rem;
  font-weight: 700;
  line-height: 2.5rem;
  letter-spacing: 0.8px;
  color: #fff;
}

.ranking-container__table__extact--btn__specific-period {
  background-color: #F36E25;
  margin-bottom: 1rem;
}

.ranking-container__table__extact--btn__score-extract {
  background-color: #FFC600;
}

.ranking-container__table__extact a {
  width: 100%;
}

@media (max-width: 1500px) {
  .ranking-container {   
    width: auto;
  }

  .ranking-container__table__extact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .ranking-container__table__extact__header {
    display: flex;
    align-items: center;  
    justify-content: center;
  }

  .ranking-container__table__extact__header img { 
    display: none;
  }
  
}
