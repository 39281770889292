.card-info__container {
  padding: 8px;
  min-width: 212px;
  min-height: 100px;
  background-color: #FFC600;
  border-radius: 8px;
}

.card-info__container__header {  
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.card-info__container__header h4 {  
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #000;  
}

.card-info__container__header img {
  width: 30px;
  height: 30px;
  color: #000;
}

.card-info__container__body {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;  
}

.card-info_container__body__info-1 {
  position: absolute;
  left: 10px;
  top: 10px;
}

.card-info_container__body__info-2 {
  position: absolute;
  right: 10px;
  top: 10px;
}