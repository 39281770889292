.edit-chair {
  padding: 10px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 3.825rem 1fr 1fr;
  row-gap: 25px;
  grid-template-areas:
    "chair"
    "subchair"
    "filter"
  ;
}

.chair {
  grid-area: chair;
  display: grid;
  column-gap: 20px;
  grid-template-columns: 3fr 1fr;

}

.subchair {
  grid-area: subchair;
  display: grid;
  column-gap: 20px;
  grid-template-columns: 3fr 1fr;
}

.filter {
  grid-area: 'filter';
}

.table {
  grid-area: 'table';
}

.btn-edit-chair {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.btn-create-subchair {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}