.week_day_modal__title {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1rem;
  text-align: center;
}

.week_day_modal__action {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}