
.presence {
    background-color: white;
    width: 972px;
    min-height: 92px;
    border-radius: 10px; 
    padding: 8px 0px;      
}

.container-presence {
    display: flex;  
    gap: 10px;  
    flex-wrap: wrap;    
    align-items: center;
    padding: 0 10px;    
}