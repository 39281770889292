.alert-container {
  border: 1px solid #635f5f;
  border-radius: 1rem;
  padding: 1rem 0.5rem;
  margin: 0.625rem 0.5rem;
  width: 100%;
}

.alert-container__header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  gap: 4rem;
}

.alert-container__header span {
  font-size: 1.5rem;
  font-weight: 900;
  color: #FFC600;
}

.alert-container p {
  text-align: center;
  font-size: 1.125rem;
  font-weight: 400;
  color: #000;
}

@media (max-width: 1500px) {
  .alert-container {      
    width: auto;
  }
}
