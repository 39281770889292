.table-members {
    padding: 0;
}
    
.table-members tbody td span {
    font-size: 13px;
}

.table-members thead th {
    font-size: 12px;
}
