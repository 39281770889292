.button-search {
    min-width: 116px;
    font-size: 13px;
    font-weight: 700;
}

.table-birthday {
    table th {
        width: 50%;
    }
}