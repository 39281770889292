.input-currency {
    border-radius: 10px;
    height: 34px;
    border: 1px solid #ced4da;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.input-currency:focus {
    border-color: #ffc600 !important;
    box-shadow: 0 0 0 1px #ffc600 !important;
    outline: 0;
}