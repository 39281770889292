.imagePreview {
  width: 100%;
  height: 180px;
  background-position: center center;
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  display: inline-block;
  margin-bottom: 5px;
}