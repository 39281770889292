.card-extract__container {
  padding: 0.5rem;
  background-color: #FFF;
  border-radius: 0.5rem;
}

.card-extract__container h2 {
  font-size: 1rem;
  font-weight: 700;
  margin-left: 30px;
}

.card-extract__container p {
  font-size: 0.9rem;
  font-weight: 500;
  margin-left: 30px;
}