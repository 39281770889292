.dashboard-container {
  display: grid;  
  grid-template-columns: 18.75rem 2fr 1fr;
  grid-template-areas: "sidebar main system";
}

.sidebar-dashboard {
  grid-area: sidebar;
  background-color: #f5f5f5;
  border-right: 1px solid #e5e5e5;
}

.main-dashboard {
  grid-area: main;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.system-dashboard {  
  grid-area: system;
  background-color: #f5f5f5;
  border-radius: 8px;
}

@media (max-width: 1500px) {
  .dashboard-container {    
    display: grid;  
    padding: 0 1rem;
    grid-template-columns: 1fr;    
    gap: 1rem;
    grid-template-areas: 
    "sidebar"
    "main"
    "system"
  ;

  }  

  .sidebar-dashboard {    
    grid-area: sidebar;    
    background-color: #f5f5f5;    
    border-right: 1px solid #e5e5e5;            
  }
  
  .main-dashboard {   
    grid-area: main; 
    background-color: #f5f5f5;    
  }
  
  .system-dashboard { 
    grid-area: system;   
    background-color: #f5f5f5;
    padding: 1rem;    
  }
}