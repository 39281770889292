.presence-container {
    display: grid;
    grid-template-columns: 40.3125rem 1fr;
    align-items: start;    
    gap: 7rem;
}

.week-presence  {
    padding: 16px;
    margin: 0 20px;
}

.week-presence h3{
    color: #000;
    font-family: Arial; 
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;  
}

.week-presence h4 {
    color: #000;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.week-presence label {
    color: #212529;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 24px; 
}

.presence-users {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 
        "presence-users-header"
        "presence-users-list"
    ;
}

.presence-guests {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 
        "presence-guests-header"
        "presence-guests-list"
    ;
}

.presence-users-header {
    grid-area: presence-users-header;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12.5rem;    
}

.presence-users-header p, .presence-guests-header p {
    color: #000;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; 
}

.presence-guests-header {
    grid-area: presence-guests-header;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12.5rem;
}

.presence-guests-list {
    grid-area: presence-guests-list;
}

.presence-users-list {
    grid-area: presence-users-list;
}

.presence-users-list-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.presence-guests-list-form {    
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.presence-guests-list-form .form-check-input, .presence-users-list-form .form-check-input{
    width: 5rem;
}

.week-footer {
    margin-top: 56px;
}

.week-footer p  span {
    color: #000;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;    
}

.week-footer button {
    width: 145px;
}

.button-show {
    width: 5.8125rem;
    background-color: #F36E25 !important;
}

.modal_delete_recorrence--title {
    text-align: center;
    font-weight: 400;
    font-size: 24px;
}

.modal_delete_recorrence__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}