.component__container__carousel {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
}


.component__container__carousel__item {  
  margin: 10px;
  padding: 10px;  
  flex: none;
  cursor: pointer;
  border-radius: 1rem;
  height: 300px;
  max-width: 280px;
}

.component__container__carousel__item img {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  object-fit: cover;
}

.component__container__buttons button {
  background-color: transparent;
  border: none;
  cursor: pointer;  
}

.component__container__buttons {
  width: 100%;
  text-align: center;
}

.component__container__buttons button:first-child img {
  transform: rotate(180deg);
}

.modal-carouel-expand__container__content img{
  width: 100%;
  border-radius: 1rem;
}