.extract_container {
  padding: 1rem;
  display: grid;
  grid-template-areas: 
  "title"
  "subtitle"
  "form"
  "btn"
  "result"
  ;
  gap: 1rem;
}

.extract_container h1 {
  grid-area: title;
  font-size: 1.8rem;
  font-weight: 700;
}

.extract_container h3 {
  grid-area: subtitle;
  font-size: 1rem;
  font-weight: 400;
}

.extract_container__form {
  grid-area: form;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.extract_container_btn_send {
  grid-area: btn;
}


.extract_container_extract {
  grid-area: result;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}