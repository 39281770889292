.input-select__id_guest {
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}
.input_guest {
  width: 280px;
}

.nexus_input {
  width: 200px;
}

.input-padrinho-row {
  width: 700px;
}

.input-padrinho-group {
  border-radius: 1rem;
}

.nexusAt {
  width: 200px;
}