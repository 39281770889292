.home-screen-container {
  padding: 16px;
}

.home-screen-container h1 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  border: 1px solid #FFC600;
  display: inline;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.home-screen-container p {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 50px;
}

.home-screen__images__wrapper {
  padding: 10px;
  width: 100%;
  border-radius: 8px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  margin-top: 16px;
  background-color: #FFFFFF;
}

.home-screen__images__wrapper__card--img img {
  width: 100%;
  height: 200px;
  border-radius: 8px;
  background-color: #FFC600;
  margin-bottom: 16px;
}

.home-screen__images__wrapper__card--action {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-screen__images__wrapper__card--action img {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

@media only screen and (max-width: 1761px) {

  .home-screen__images__wrapper {
    padding: 10px;
    width: 100%;
    border-radius: 8px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    margin-top: 16px;
    background-color: #FFFFFF;
  }
}

@media only screen and (max-width: 960px) {

  .home-screen__images__wrapper {
    padding: 10px;
    width: 100%;
    border-radius: 8px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;
    margin-top: 16px;
    background-color: #FFFFFF;
  }
}