.presence-user {
    background-color: #CED4DA;
    border-radius: 4px;
    border: 1px solid #CED4DA;
    min-width: 50px;    
}

.presence-user .container {
    padding: 4px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center; 
    min-width: 50px;
}

.presence-user .container span {
    font-size: 12px;
    font-weight: 400;
    color: #000;
    font-style: normal; 
}

.presence-user .close {
    font-size: 12px;
    font-weight: 400;
    color: #000;
    font-style: normal; 

    cursor: pointer;
}